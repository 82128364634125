.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#walletBtn, #ordBtn{
  margin-left: 10px;
}
@font-face {
  font-family: 'nountown';
  src: url('../public/fonts/nountown.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'londrina-regular';
  src: url('../public/fonts/LondrinaSolid-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'londrina-thin';
  src: url('../public/fonts/LondrinaSolid-Thin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.text-info,.text-success,.text-danger{
  font-family: 'londrina-regular'!important;
}
.text-info{
  color: #2d006a!important;
}
.btn,h1,h2,h3{
  font-family: 'londrina-regular'!important;
}
.btn-info{
  color:white!important;
}
.btn-primary{
  background-color: #2d006a;
  border-color: #2d006a;
}
.btn-primary:disabled{
  background-color: #2d006a;
  border-color: #2d006a;
  opacity: .65;
}
.btn-primary:hover{
  background-color: #ff30bc;
  border-color: #ff30bc;
}
.btn-outline-secondary{
  border-color: #2d006a;
  color: #2d006a; 
}
.btn-outline-secondary:hover{
  border-color: #2d006a;
  background-color: #2d006a;
}
.navbar {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0; /* Set to the top of the viewport */
  z-index: 1000; /* Ensure the navbar stays on top of other content */
  background-color: white!important;
}
.navbar-icon {
  margin-right: .5rem; /* Adjust spacing to match other items */
  cursor: pointer;
}

.img-fluid{
  width: 400px
}
#fullForm{
  margin-top: 1rem!important;
}
.fab,.fas{
  color:#2d006a!important;
  margin-right:10px;
  vertical-align: sub;
}


.hero-section {
  position: relative;
  text-align: center;
  padding-bottom: 150px;
}

.hero-section img {
  width: 33%;
  height: auto;
  cursor: pointer;
  top: 50%;
  border-radius: 36px;
}

.connect-button {
  position: absolute;
  top: 650px; /* Adjust based on your needs */
  left: 50%;
  transform: translateX(-50%);
}
.sticky-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f9fa; /* Light grey background, adjust as needed */
  border-top: 1px solid #dee2e6; /* Add a top border for some visual separation */
  padding: 10px; /* Add some padding */
  text-align: center; /* Center the text and button */
  display: flex;
  justify-content: space-around; /* Evenly space the items */
  align-items: center;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust the space between cards */
}

.card {
  flex: 0 0 calc(25% - 20px); /* Fixed-size cards, four per row, accounting for the gap */
  border: 1px solid #2d006a;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  height: 300px; /* Fixed height for consistency */
}

.card-content {
  width: 100%;
  height: 150px; /* Adjust height for content display */
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Hide overflow text */
  margin-bottom: 10px;
  text-overflow: ellipsis;
  color: white;
  background-color: black;
}

.card-details {
  text-align: center;
}

.card img {
  max-width: 100%;
  max-height: 100%; /* Ensure images fit within the content area */
  image-rendering:pixelated;
}


.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  box-shadow: -11px -7px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border: 2px solid #2d006a;
  border-radius: 8px;

}
.info-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.info-popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  text-align: center;
}

.fee-rate-slider {
  margin: 10px 0;
}

.fee-rate-slider input[type="range"] {
  width: 100%;
}

.fee-rate-slider label {
  display: block;
  text-align: center;
}
